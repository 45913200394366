<template>
  <div>
    <carousel class="mb-4"></carousel>
    <div class="row mt-1 mb-2 g-2">
      <div class="col-md-6">
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <img src="../../assets/rushan_1.jpeg" class="img-fluid rounded-start w-100 h-100 object-fit-cover" alt="...">
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">大乳山旅游度假区</h5>
                <p class="card-text">大乳山风景区位于山东威海乳山市大乳山周围，是一处集观光旅游、休闲度假、文化娱乐、养生康体的综合性大型旅游胜地。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card mb-3">
          <div class="row g-0">
            <div class="col-md-4">
              <img src="../../assets/rushan_2.jpeg" class="img-fluid rounded-start w-100 h-100 object-fit-cover" alt="...">
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <h5 class="card-title">银滩旅游度假区</h5>
                <p class="card-text">乳山银滩人文自然景观丰富。银滩沙子洁白如银，因而获得“银滩”之名，被誉为“天下第一滩”、“东方夏威夷”。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Carousel from '@/components/home/Carousel'
export default {
  name: 'HomePage',
  data() {
    return {

    }
  },
  components: {
    Carousel
  }
}
</script>
