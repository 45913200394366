import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/home/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/towns',
    name: 'Towns',
    component: () => import( /* webpackChunkName: "about" */ '../views/town/TownList.vue')
  },
  {
    path: '/towns/:townId',
    name: 'TownDetail',
    component: () => import( /* webpackChunkName: "about" */ '../views/town/TownDetail.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue')
  }
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

export default router
