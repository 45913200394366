<template>
  <div>
    <div class="container">
      <header class="blog-header py-3">
        <div class="row flex-nowrap justify-content-between align-items-center">
          <div class="col-md-6 col-sm-12 text-left">
            <div class="d-flex align-items-center">
              <img src="./assets/logo.png" class="me-3" width="100" height="60" alt="">
              <span class="portal-header-logo font-sj" href="#">母爱圣地 幸福乳山</span>
            </div>
          </div>
        </div>
      </header>
      <div class="hehe bg-white rounded px-3 mb-3">
        <div class="nav-scroller py-1 mb-2">
          <nav class="nav d-flex justify-content-between">
            <router-link class="p-2 link-dark link-offset-2" :to="{name: 'Home'}">乳山</router-link>
            <router-link class="p-2 link-dark link-offset-2" :to="{name: 'Towns'}">乡镇</router-link>
            <a class="p-2 link-dark link-offset-2" href="#">风土</a>
            <a class="p-2 link-dark link-offset-2" href="#">地理</a>
            <a class="p-2 link-dark link-offset-2" href="#">回忆</a>
            <a class="p-2 link-dark link-offset-2" href="#">旅游</a>
            <a class="p-2 link-dark link-offset-2" href="#">大乳山</a>
            <a class="p-2 link-dark link-offset-2" href="#">银滩</a>
            <a class="p-2 link-dark link-offset-2" href="#">小吃・炸甩</a>
            <a class="p-2 link-dark link-offset-2" href="#">人文</a>
            <a class="p-2 link-dark link-offset-2" href="#">文章</a>
            <a class="p-2 link-dark link-offset-2" href="#">联系我们</a>
            <a class="p-2 link-dark link-offset-2" href="#">关于</a>
          </nav>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <footer class="footer mt-auto pt-4 pb-3">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <p class="text-center" style="margin-bottom: 0px;">
              母爱圣地，幸福乳山；记住乡愁，传承经典。
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="icp">
              <a target="_blank" class="link-dark" href="https://beian.miit.gov.cn/">
                鲁ICP备17018306号-6
              </a>&nbsp;
              &copy; 2016-2023 <a class="link-dark" href="https://www.linshuaishuai.com">林帅帅</a>. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
.portal-header-logo {
  font-size: 1.5em;
}
/* .hehe {
  border: 10px solid transparent;
  border-image-source: url(./assets/border.png);
  border-image-width: 400 400;
  border-image-repeat: stretch stretch;
  border-image-slice: 750;
}
.hehe {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.nav-scroller {
  margin: 0px !important;
} */
</style>
