<template>
  <div id="carouselExampleCaptions" class="carousel slide">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="ratio ratio-21x9">
          <img src="../../assets/rushan_2.jpeg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
          <h2>大乳山旅游度假区</h2>
        </div>
      </div>
      <div class="carousel-item">
        <div class="ratio ratio-21x9">
          <img src="../../assets/rushan_1.jpeg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
          <h2>银滩旅游度假区</h2>
        </div>
      </div>
      <div class="carousel-item">
        <div class="ratio ratio-21x9">
          <img src="../../assets/rushan_2.jpeg" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-caption d-none d-md-block">
          <h2>大乳山旅游度假区</h2>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
  <!--
  <div>
    <b-carousel
      v-model="slide"
      :interval="4000"
      controls
      indicators
      style="text-shadow: 1px 1px 2px #333;"
      class="rounded overflow-hidden w-100 h-100"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide class="w-100 h-100">
        <template v-slot:img>
          <b-aspect aspect="8:3">
            <img
              class="d-block w-100 position-absolute"
              width="0"
              height="0"
              style="width: 100%; height: 100%"
              src="../../assets/rushan_2.jpeg"
              alt="image slot"
            >
          </b-aspect>
        </template>
        <h2 class="d-none d-md-block">大乳山旅游度假区</h2>
      </b-carousel-slide>
      <b-carousel-slide>
        <template v-slot:img>
          <b-aspect aspect="8:3">
            <img
              class="d-block w-100 position-absolute"
              width="1024"
              height="420"
              src="../../assets/rushan_1.jpeg"
              alt="image slot"
            >
          </b-aspect>
        </template>
        <h2 class="d-none d-md-block">银滩旅游度假区</h2>
      </b-carousel-slide>
      <b-carousel-slide>
        <template v-slot:img>
          <b-aspect aspect="8:3">
            <img
              class="d-block w-100 position-absolute"
              width="1024"
              height="420"
              src="../../assets/rushan_2.jpeg"
              alt="image slot"
            >
          </b-aspect>
        </template>
        <h2 class="d-none d-md-block">大乳山旅游度假区</h2>
      </b-carousel-slide>
    </b-carousel>
  </div>
  -->
</template>
<script>
  export default {
    name: 'HomeCarousel',
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart(slide) {
        slide
        this.sliding = true
      },
      onSlideEnd(slide) {
        slide
        this.sliding = false
      }
    }
  }
</script>
